<template>
  <div class="mb-5 text-nowrap border-bottom overflow-auto">
    <router-link to="/manage-bundle" class="btn btn-wz-tab">Manage Bundle</router-link>
    <router-link to="/add-bundle" class="btn btn-wz-tab">Add Bundle</router-link>
    <router-link :to="$route.params.id ? '/edit-bundle/' + $route.params.id : '#'" class="btn btn-wz-tab" :disabled="!$route.params.id">Edit Bundle</router-link>
    <router-link :to="$route.params.id ? '/detail-bundle/' + $route.params.id : '#'" class="btn btn-wz-tab" :disabled="!$route.params.id">Detail Bundle</router-link>
  </div>
</template>
<script>
export default {
  name: "BundleNavTab",
}
</script>
